import { Box } from "@mui/material"

const validateData = (data, rows) => {

    const response = []

    if (!data?.product)
        response.push(<Box>Selecione um produto.</Box>)

    if (!data?.price > 0)
        response.push(<Box>Digite o preço.</Box>)

    if (!data?.amount > 0)
        response.push(<Box>Digite a quantidade.</Box>)

    if (data?.product){
    
        if(rows.some(each => each.product === data.product.id)){
            response.push(<Box>Este produto já foi adicionado.</Box>)
        }
    }
    
    if (response.length === 0)
        return ''

    return (response)
}

export default validateData