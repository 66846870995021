import React, { useState } from 'react'
import { Box, IconButton, Tooltip} from '@mui/material'
import dayjs from 'dayjs';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ModalProduct from './modalProduct';


const openModal = (setModalProduct, file) => {
    setModalProduct(file)
}


const PreboardingDocuments = ({state, setState, saveDataReset}) => {

    const [modalProduct, setModalProduct] = useState(null)

    const handleCloseReset = () => {
        setModalProduct(null)
        setState({documents:JSON.parse(state.dataReset)})
    }
    

    return(
        <>

        {
            modalProduct &&
            <ModalProduct
            data={modalProduct}
            importacao={state}
            setInvoice={setState}
            handleClose={handleCloseReset}
            />
        }


            {
                state.documents && state.documents.length > 0 ? (
                    state.documents.map((document, index) => (
                        <Accordion key={index}>
                            <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
                                <Typography>{dayjs(document.order_date).format('MM/YYYY')}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {/* Documentos */}
                                <Typography>Documentos:</Typography>
                                <br />
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                                    {
                                        document.orderfile_set.length === 0 ? (
                                            <Box>
                                                <h6>Nenhum documento registrado.</h6>
                                            </Box>
                                        ) : (
                                            document.orderfile_set.map((file, index) => (
                                                <Card key={index} sx={{ height: '4.5rem' }}>
                                                    <CardContent>
                                                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                                            <Typography>
                                                                {file.name} - {file.number} - {file.type} - Total de Pneus: {file.total_amount} (Saldo: {file.productfile_set?.reduce((acc, product) => acc + product.balance, 0)})
                                                            </Typography>
                                                            <Tooltip title='Visualiza os produtos deste documento'>
                                                                <IconButton onClick={() => {openModal(setModalProduct, file);saveDataReset();}}><VisibilityIcon/></IconButton>                                                                                                                                                       
                                                            </Tooltip>
                                                        </Box>
                                                    </CardContent>
                                                </Card>
                                            ))
                                        )
                                    }
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    ))
                ) : (
                    <Box>
                        <h6>Nenhum pedido para este exportador.</h6>
                    </Box>
                )
            }
        
        </>
    )
}

export default PreboardingDocuments;