import React, { useContext, useReducer } from 'react'
import { lockedReducer } from '../Utils/reducers'
import { Box } from '@mui/material'
import ColorButton from '../Buttons/ColorButton'
import './styles.css'
import { getSession } from '../../contexts/auth'
import { externalComponent } from '../AppRoutes'

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';

import Importacao from './importacao';
import PreBoarding from './preBoarding'

const positions = (state, setState) => {
    const user = getSession()
    return ({
        'nova_importacao': {name: 'Inicial', component: (<Importacao parent={state} setParent={setState}/>) , icon: (<AddCircleOutlineIcon />), permission: user.permissions.view_schedule | user.permissions.view_proposal | user.permissions.view_serviceorder},
        'pre_embarque': {name: 'Pré Embarque', component: (<PreBoarding parent={state} setParent={setState}/>) , icon: (<ArrowCircleRightIcon />), permission: user.permissions.view_schedule | user.permissions.view_proposal | user.permissions.view_serviceorder},
    })
}

const Cockpit = () => {

    const [state, setState] = useReducer(lockedReducer, {
        position: 'nova_importacao',
        selectedForward: null,
    })
    const positions_object = positions(state, setState)
    const position_list = Object.entries(positions_object)
    const {isMobile} = useContext(externalComponent)
    return (
        <>

            <Box className='cockpitmenu'>
                <Box className={isMobile ? 'cockpitbuttonMobile': 'cockpitbutton' }>
                    {position_list.map(([key, value]) => (
                        <ColorButton
                            startIcon={value.icon}
                            reverse={key === state.position ? false : true}
                            onClick={() => setState({ position: key })}>{value.name}
                        </ColorButton>
                    ))}
                </Box>
                    <Box sx={{ height: '100%', overflow: 'hidden' }}>
                        {positions_object[state.position]?.component}
                    </Box>
            </Box>

        </>
    )

}
export default Cockpit