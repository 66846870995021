import { Box } from "@mui/material"

const validateDataNext = (data) => {

    const response = []

    switch (data?.step){
        case 0:
            if (!data?.saved)
                response.push(<Box>Salve a importação</Box>)
        break
        case 1:
            if (!data?.saved)
                response.push(<Box>Selecione uma data.</Box>)
        break
        default:
            break
    }


    if (response.length === 0)
        return ''

    return (response)
}

export default validateDataNext