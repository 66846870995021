import React, { useState } from 'react'
import { Box } from '@mui/material'
import ColorButton from './ColorButton'
import ModalLog from '../Pedidos/Painel/modalLog'

const SquareButtons = ({data}) => {

    const [dataModal, setDataModal] = useState(null)

    const handleClose = () => {
        setDataModal(null)
    }

    return(
        <>

        {
            dataModal &&
            <ModalLog data={dataModal} handleClose={handleClose}/>
        }

        <Box sx={{display:'flex', flexDirection:'row-reverse',gap:'0.3rem'}}>
            
            {
                data?.map((log, index) => {

                    const number = 1 + index
                    return (
                        <ColorButton key={log.id} onClick={() => setDataModal(log)}>{number}º</ColorButton>
                    )
            })}
            
        </Box>
        
        </>
    )
}

export default SquareButtons