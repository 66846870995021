import axios from "axios";
import toast from 'react-hot-toast'
import translatedMessages, { defaultMessages } from "../components/Utils/translatedMessages";
import UpdateUserData from "../components/Utils/updateUserData";

export const defaultBackend = process.env.REACT_APP_URL_PLATESYS

export const api = axios.create({
    baseURL: defaultBackend,
})

export const downloadFile = async (url) => {
    const response = await api.get(url, { responseType: 'blob' })
    const href = URL.createObjectURL(response.data);
    const link = document.createElement('a');
    link.href = href;
    link.target = "_blank";
    //const filename = url.split('/')
    //link.setAttribute('download', filename[filename.length - 2]); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
}

api.interceptors.request.use(async (config) => {
    const token = sessionStorage.getItem('token')
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
});

const validate_user_token = (response) => {
    if (response.headers.enterprise_token && response.headers.user_token){
        if ((window.enterprise_token &&
            (response.headers.enterprise_token !== window.enterprise_token)) ||
            (window.user_token && (response.headers.user_token !== window.user_token))
        ) {
            UpdateUserData()
        }
        window.enterprise_token = response.headers.enterprise_token
        window.user_token = response.headers.user_token
    }
}

api.interceptors.response.use((response) => {
    validate_user_token(response)

    return response
},

    async function (err) {
        if (!err.response)
            return err;
        const response = err.response

        validate_user_token(response)

        let responseMessage = defaultMessages['pt-BR']

        const errorMessage = response?.data?.detail

        if (errorMessage) {
            const transMessage = translatedMessages['pt-BR'][errorMessage]
            if (transMessage)
                responseMessage = transMessage
        }

        if (response.status === 500) {
            toast.error(responseMessage)
        }

        if (response.status === 400) {
            toast.error(JSON.stringify(response.data))
        }

        return response;
    })



let newUrl = defaultBackend.replace('/api/', '')

export const mediaURL = newUrl + '/media/'

export const createSession = async (email, password) => {

    return api.post('/token/', { email, password })
}

export const getCredentials = async () => {
    return api.get('/integrations/')
}
