const IntegerOnly = (value, min, max, returnString=false) => {
    const newvalue = value.replace(new RegExp(/[^\d]/, "ig"), "")
    if (returnString) return newvalue
    if (Number(newvalue) < min)
        return Number(min)
    if  (Number(newvalue) > max)
        return Number(max)
    return Number(newvalue)
}

export const FloatOnly = (value, min, max, returnString=false) => {
    const newvalue = value.replace(/[^-\d.]+/g, "")
    return newvalue
}

export const NumbersOnly = (value) => {
    return String(IntegerOnly(value, '', '', true))
}

export const stringToInt = (str) => {
    const parsedInt = parseInt(str, 10); // Specify radix 10 to ensure decimal conversion
    return isNaN(parsedInt) ? 0 : parsedInt;
}

export default IntegerOnly